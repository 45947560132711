import * as React from 'react';
import { Row, Cell } from './grid';
import { Button } from './form';
import FormIntro from './form-intro';
import * as styles from './form.module.scss';

const PrivacyComplaintFormStep1 = (props) => {
  const {
    onSubmit,
  } = props;

  return (
    <form
      className={styles.page}
      onSubmit={onSubmit}
      noValidate>
      <FormIntro>
        <Row>
          <Cell className="large-7">
            <h1>What you should know before using this form</h1>
            <p>Use this form to make a privacy complaint to OVIC about how a Victorian public sector organisation has handled your personal information.</p>
            <p>Before making a complaint to us it is important to read about <a href="https://ovic.vic.gov.au/privacy/for-the-public/privacy-complaints/" target="_blank" rel="noopener noreferrer">what to do before making a complaint, our privacy complaints process, and what happens next.</a></p>

            <h3>How will the information I provide be used?</h3>
            <p>We use the information you provide to help us resolve your complaint. This includes contacting the organisation to notify them of your complaint, discussing the complaint with them, and collecting information about you from them.</p>
            <p>We will usually share your responses to questions 10, 11 and 12 with the organisation.</p>
            <p>Information you enter onto this form is stored locally, in your browser, until you submit it. If you do not submit the form, the information you enter will stay stored locally in your browser until you clear the browser cache or delete any information you entered onto the form.</p>
            <p>We manage your personal information in accordance with our <a href="https://ovic.vic.gov.au/about-us/internal-policies-procedures-and-registers/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>

            <h3>How should I fill out the form?</h3>
            <p>The form will ask you questions about:</p>
            <ul>
              <li>What happened?</li>
              <li>Why you think your privacy has been breached?</li>
              <li>How you have been impacted? and</li>
              <li>How would you like your complaint to be resolved?</li>
            </ul>
            <p>Please note that all questions require a mandatory answer.</p>
            <p>It is important that you provide as much detail as you can, as this will help us understand your complaint and save time asking you for more information.</p>
            <p>You can attach copies of relevant documents including evidence that supports your allegations, and documents showing how you have been affected and any response from the organisation.</p>
            
            <Button 
              text="Start your privacy complaint"
            />
          </Cell>
          <Cell className="large-5">
            <Button 
              text="Start your privacy complaint"
              className="show-for-large"
            />
            <p><em>This form will take 15 - 30 minutes to complete.</em></p>
            <p><em>You will be emailed a copy of your submission.</em></p>
            <h2>Need help?</h2>
            <p>Contact us by phone on <strong>1300 006 842</strong> or email at <a href="mailto:privacy@ovic.vic.gov.au">privacy@ovic.vic.gov.au</a>.</p>
            <h2>More information?</h2>
            <p>You can find more information about OVIC's <a href="https://ovic.vic.gov.au/privacy/for-the-public/privacy-complaints/" target="_blank" rel="noopener noreferrer">privacy complaints process on our website.</a></p>
            <Button 
              text="Start your privacy complaint"
              className="hide-for-large"
            />
          </Cell>
        </Row>
      </FormIntro>
    </form>
  )
}

export default PrivacyComplaintFormStep1;
